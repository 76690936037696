import { ref, computed } from "vue"
import { defineStore } from "pinia"
import { apiClient } from "/src/http/http"
import { Organization, OrganizationId } from "../models/organization"
import { User } from "../models/user"

export const useAuthStore = defineStore("user", () => {
    const currentUser = ref<User | null>(null)
    const currentUserOrganizations = ref<Organization[]>([])
    const currentOrganizationId = ref<OrganizationId | null>(null)

    const currentOrganization = computed(() => {
        if (!currentOrganizationId.value) return null
        const organization = currentUserOrganizations.value.find(
            (org) => org.id === currentOrganizationId.value
        )
        if (!organization) throw new Error("Organization not found")
        return organization
    })

    const currentUserRole = computed(
        () => currentOrganization.value?.user_role ?? null
    )

    const csrfToken = ref<string | null>(null)
    const apiKey = ref<string | null>(null)

    const isLoggedIn = computed(() => currentUser.value != null)

    const isRegistrationComplete = computed(
        () =>
            currentUser.value &&
            currentUser.value.registered &&
            currentUserOrganizations.value.length > 0
    )

    const isSuperAdmin = computed(
        () => currentUser.value?.is_superadmin === true
    )

    const isAdmin = computed(
        () =>
            currentUserRole.value === "owner" ||
            currentUserRole.value == "admin" ||
            isSuperAdmin.value
    )

    const getCsrfToken = async () => {
        // try {
        //     const response = await apiClient.get("/user/csrf")
        //     csrfToken.value = response.response.csrf_token
        // } catch (error) {
        //     // TODO: redirect to error page
        //     console.log(error)
        // }
    }

    const retrieveUser = async () => {
        const {
            user,
            organizations,
        }: { user: User; organizations: Organization[] } =
            await apiClient.get("/user")
        currentUser.value = user
        currentUserOrganizations.value = organizations
        currentOrganizationId.value = organizations[0]?.id ?? null
    }

    const logout = async () => {
        await apiClient.post("/logout")

        currentUser.value = null
        currentOrganizationId.value = null

        window.location.href = "/"
    }

    const changeOrganization = (newOrganizationId: OrganizationId) => {
        currentOrganizationId.value = newOrganizationId
    }

    return {
        currentUser,
        currentOrganization,
        currentUserOrganizations,
        currentUserRole,
        csrfToken,
        apiKey,
        isLoggedIn,
        isRegistrationComplete,
        isSuperAdmin,
        isAdmin,
        changeOrganization,
        getCsrfToken,
        retrieveUser,
        logout,
    }
})

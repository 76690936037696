/** @type {import('vue-router').RouterOptions['routes']} */

export const routes = [
    // HOME
    // We use this redirect so we can generally point a link to "home" and decide here
    //  to which view it should redirect.
    {
        path: "",
        name: "home",
        // TODO: redirect to dashboard once available
        redirect: { name: "documents-list" },
    },
    // LOGIN & REGISTRATION
    {
        path: "/login",
        name: "login",
        component: () => import("/src/views/LoginView.vue"),
        meta: {
            requiresAuth: false,
            requiresAnonymous: true,
            metaTags: {
                name: "description",
                content:
                    "Log into Evolution AI’s acclaimed Transcribe platform.",
            },
        },
    },
    {
        path: "/setup",
        name: "register",
        component: () => import("/src/views/UserSetupView.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    // // DASHBOARD VIEWS
    // {
    //     path: "/dashboard",
    //     name: "dashboard",
    //     component: () => import("/src/dashboard/views/DashboardMain.vue"),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    // DOCUMENTS VIEWS
    {
        path: "/documents",
        component: () => import("/src/views/DocumentListView.vue"),
        name: "documents-list",
        meta: {
            requiresAuth: true,
            crumbs: [{ name: "Documents", to: { name: "documents-list" } }],
        },
    },
    {
        path: "/documents/:id",
        name: "document-view",
        component: () => import("/src/views/DocumentView.vue"),
        props: (route) => ({
            documentId: route.params.id,
            // pageNumber: parseInt(route.query?.p || 0),
        }),
        meta: {
            requiresAuth: true,
            crumbs: [
                { name: "Documents", to: { name: "documents-list" } },
                { name: "%document" },
            ],
        },
    },
    // {
    //     path: "/documents/:id/annotate",
    //     name: "annotate-document",
    //     component: () => import("/src/documents/views/AnnotateView.vue"),
    //     props: (route) => ({
    //         documentId: route.params.id,
    //         subdocumentIdx:
    //             "subdocument" in route.query
    //                 ? parseInt(route.query.subdocument)
    //                 : -1,
    //     }),
    //     meta: {
    //         requiresAuth: true,
    //         crumbs: [
    //             { name: "Documents", to: { name: "documents-list" } },
    //             { name: "%document" },
    //             { name: "Annotate" },
    //         ],
    //     },
    // },
    {
        path: "/documents/:id/classify",
        name: "classify-pages",
        component: () => import("/src/views/ClassifyPagesView.vue"),
        props: (route) => ({
            documentId: route.params.id,
        }),
        meta: {
            requiresAuth: true,
            crumbs: [
                { name: "Documents", to: { name: "documents-list" } },
                { name: "%document" },
                { name: "Classify" },
            ],
        },
    },
    // // DOCUMENT TYPES VIEWS
    // {
    //     path: "/document-types",
    //     name: "document-types",
    //     component: () => import("/src/document-types/views/DocTypeMain.vue"),
    //     meta: {
    //         requiresAuth: true,
    //         crumbs: [{ name: "Document Types" }],
    //     },
    // },
    // // TEAMS VIEWS
    // {
    //     path: "/teams",
    //     component: () => import("/src/teams/views/TeamsMain.vue"),
    //     name: "teams",
    //     meta: {
    //         requiresAuth: true,
    //         crumbs: [{ name: "Team" }],
    //     },
    // },
    // // OUTPUT VIEWS
    // {
    //     path: "/output",
    //     component: () => import("/src/output/views/OutputTemplate.vue"),
    //     name: "output",
    //     meta: {
    //         requiresAuth: true,
    //         crumbs: [{ name: "Output" }],
    //     },
    // },
    // // BILLING VIEW
    // {
    //     path: "/billing",
    //     component: () => import("/src/billing/views/BillingMain.vue"),
    //     name: "billing",
    //     meta: {
    //         requiresAuth: true,
    //         crumbs: [{ name: "Billing" }],
    //     },
    // },
    // // CONFIGURATION VIEW
    // {
    //     path: "/configuration",
    //     component: () => import("/src/configuration/views/ConfigurationMain.vue"),
    //     name: "configuration",
    //     meta: {
    //         requiresAuth: true,
    //         requiresSuperAdmin: true,
    //         crumbs: [{ name: "Configuration" }],
    //     },
    // },
    // // AUDIT LOG VIEW
    // {
    //     path: "/audit-log",
    //     component: () => import("/src/audit-log/views/AuditLogMain.vue"),
    //     name: "audit-log",
    //     meta: {
    //         requiresAuth: true,
    //         crumbs: [{ name: "Audit Log" }],
    //     },
    // },
    // // API DOCUMENTATION
    // {
    //     path: "/api-documentation",
    //     component: () => import("/src/api-documentation/views/ApiMain.vue"),
    //     name: "api-documentation",
    //     meta: {
    //         requiresAuth: true,
    //         crumbs: [{ name: "API documentation" }],
    //     },
    // },
    // OTHER VIEWS
    {
        path: "/error/:code(\\d{3})?",
        name: "error",
        component: () => import("/src/components/core/Error.vue"),
        meta: {
            requiresAuth: false,
        },
        props: (route) => ({
            code: parseInt(route?.params?.code ?? 500),
        }),
    },
    {
        // Match any other url and redirect to 404
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("/src/components/core/Error.vue"),
        meta: {
            requiresAuth: false,
        },
        props: () => ({
            code: 404,
            title: "Page Not Found",
            message:
                "The page you are trying to access does not exist, have you typed a correct URL?",
        }),
    },
]

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE"

export class HTTPError extends Error {
    name: string
    code: number

    constructor(response: Response) {
        // TODO: check if error response contain json and use that as message
        super(response.statusText)
        this.name = "HTTPError"
        this.code = response.status
    }
}

// Client for internal API calls. Base URL is defined in .env files.
const apiClient = async (
    method: HttpMethod,
    endpoint: string,
    {
        payload = null,
        params = null,
        headers = null,
    }: {
        payload?: any
        params?: { [key: string]: string | number | boolean } | null
        headers?: { [key: string]: string } | null
    } = {
        payload: null,
        params: null,
        headers: null,
    }
) => {
    const baseURL = import.meta.env.VITE_API_BASEURL || window.location.origin

    if (!endpoint.startsWith("/")) endpoint = "/" + endpoint

    const url = new URL(import.meta.env.VITE_API_PREFIX + endpoint, baseURL)

    let req: RequestInit = {
        method,
        headers: Object.assign({}, apiClient.headers, headers ?? {}),
        mode: "cors",
        credentials: "include",
    }

    if (params != null)
        Object.entries(params).forEach(([k, v]) => {
            if (v) url.searchParams.append(k, v)
        })

    if (payload != null) req["body"] = JSON.stringify(payload)

    return fetch(url, req).then(async (response) => {
        if (!response.ok) {
            throw new HTTPError(response)
        } else if (
            response.headers.get("Content-Type")?.includes("application/json")
        ) {
            return response.json()
        } else {
            return response.text()
        }
    })
}

apiClient.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "X-CSRFToken": document.querySelector("meta[name=csrf-token]").content,
}

apiClient.get = apiClient.bind(null, "GET")
apiClient.post = apiClient.bind(null, "POST")
apiClient.put = apiClient.bind(null, "PUT")
apiClient.delete = apiClient.bind(null, "DELETE")

export { apiClient }
